@import '../variables.module.scss';

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  backdrop-filter: blur(1px) brightness(60%);
}

.modal {
  max-height: 90vh;
  max-width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  background-color: $white;
  border-radius: 4px;
  opacity: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: $white;
}

.modalContent {
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 16px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid $borderSoft200;

  svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}

@import '../../../variables.module.scss';

.toast {
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 16px 20px;
  max-width: 424px;
  background-color: $white;
}

.success {
  border-color: $success600;
  color: $success600;
}

.error {
  border-color: $error600;
  color: $error600;
}

.info {
  border-color: $primary600;
  color: $primary600;
}

.toastIcon {
  padding-right: 12px;
  color: inherit;
}

.toastBody {
  padding-right: 12px;
  max-width: 200px;
  color: inherit;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

@import '../../../../variables.module.scss';

.tableContainer {
  padding-top: 24px;
  width: 100%;
  overflow-x: auto;
}

.table {
  margin-top: 12px;
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  color: $textStrong900;

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid $borderSoft200;
  }

  th {
    background-color: $bgWeak50;
    font-weight: 600;
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
  }

  tbody tr:nth-child(even) {
    background-color: $bgWeak50;
  }
}

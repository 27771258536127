@import '../../variables.module.scss';

.mapContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-width: 400px;
  min-height: 500px;
}

@import '../../variables.module.scss';

.portal {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.mainContainer {
  padding: 32px;
  flex-grow: 1;

  @include mobile {
    margin-left: 0;
    padding: 32px 16px;
    min-height: calc(100vh - 72px);
  }
}

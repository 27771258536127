.rdrCalendarWrapper {
  font-family: 'Inter', sans-serif;
  font-size: 14px;

  .rdrWeekDay {
    color: #dddfe4;
  }

  .rdrDayActive {
    color: #383d43;
  }

  .rdrDateDisplay {
    margin-top: 0;
  }

  .rdrMonthAndYearWrapper {
    padding-top: 0;
  }

  .rdrDateDisplayWrapper {
    background-color: transparent;
  }

  .rdrDayNumber span {
    font-weight: 400;
  }

  .rdrDayDisabled {
    background-color: #fff;

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      filter: none;
      background-color: #fff;
    }

    .rdrDayNumber span {
      color: #dddfe4 !important;
    }
  }

  .rdrDateDisplayItem {
    border-color: #dddfe4;
    box-shadow: none;
  }

  .rdrNextPrevButton {
    background-color: #fff;
    border: 1px solid #dddfe4;
  }

  .rdrMonth {
    padding: 0 0 1em 0;
    width: 20em;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #383d43 !important;
  }

  .rdrDay .rdrStartEdge,
  .rdrDay .rdrEndEdge,
  .rdrDay .rdrSelected {
    background-color: #0a77ff;

    ~ .rdrDayNumber span {
      color: #fff !important;
    }
  }
}

.rdrStartEdge {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rdrEndEdge {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrInRange,
  .rdrEndEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrInRange,
  .rdrStartEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrDayInPreview,
  .rdrDayStartPreview {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.rdrDayStartPreview {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rdrDayEndPreview {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@import '../../../variables.module.scss';

.label {
  display: flex;
  align-items: center;
  gap: 2px;
  padding-bottom: 6px;
}

.tooltip {
  margin-left: 4px;
}

.required {
  color: $primaryBase;
}

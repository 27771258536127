.tablePagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
}

.tablePaginationPrev {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  width: 110px;
}

.tablePaginationNext {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  width: 110px;
}

.pages {
  min-width: 75px;
}

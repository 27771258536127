@import '../../../../variables.module.scss';

.createCardContainer {
  border-radius: 12px;
  width: 352px;
  background-color: white;
  border-color: $neutral700;
  border-style: dashed;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 230px;

  &:hover {
    background-color: $neutral100;
  }

  @include mobile {
    min-height: 120px;
    width: 343px;
  }
}

.createCardContent {
  margin: auto;
  text-align: center;
}

.cardContainer {
  border: 1px solid $borderSoft200;
  border-radius: 8px;
  min-width: 258px;
}

.cardHeader {
  padding: 12px;
  border-bottom: 1px solid $borderSoft200;
}

.cardBody {
  padding: 12px;
}

.cardBodyRow {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.valueRow {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $textStrong900;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  border-top: 1px solid $borderSoft200;
}

.footerLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footerRight {
  display: flex;
  align-items: center;
}

.customType {
  color: $primaryBase;
}

.standardType {
  color: $textSoft400;
}

@import '../../../variables.module.scss';

.item {
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 24px;
}

.itemBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  text-align: left;
  background-color: transparent;
  border: none;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  font-family: inherit;
  font-size: inherit;
}

.itemBtn:hover {
  background-color: $neutral100;
}

.itemBtnExpanded {
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  margin-left: 16px;
  margin-right: 16px;
  padding: 16px 0;
  border-top: 1px solid;
}

.itemTablePanel {
  border-top: 1px solid;
}

.chevron {
  margin-left: 24px;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}

@import '../../../../../variables.module.scss';

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
  border-bottom: 1px solid $borderSoft200;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  a {
    color: $primaryBase;
    text-decoration: none;
    font-weight: 500;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@import '../../../variables.module.scss';

.filtersBar {
  display: flex;

  @include desktop {
    justify-content: space-between;
  }

  @include mobile {
    flex-start: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.rightFilters {
  display: flex;
  gap: 16px;

  @include mobile {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

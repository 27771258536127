@import '../../../../../variables.module.scss';

.label {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.comparisonDollars {
  margin-top: 6px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// html {
//   height: -webkit-fill-available;
// }

// body {
//   min-height: 100vh;
//   min-height: -webkit-fill-available;
//   width: 100vw;
// }

body {
  min-height: 100vh;
}

#root {
  width: 100%;
  min-height: inherit;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentRow {
  display: grid;
  grid-template-columns: 50% 50%;
}

.label {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
  padding-bottom: 6px;
}

@import '../../../../variables.module.scss';

.report {
  width: 1268px;
  padding: 20px;
  margin: auto;
  text-align: left;
  color: $textStrong900;
  background: white;
}

.logo {
  margin-top: 12px;
  margin-bottom: 38px;
}

.graphContainer {
  display: block;
  width: 100%;
  margin: 24px auto;

  .graph {
    canvas {
      height: 500px !important;
      margin: auto;
    }
  }
}

.contact {
  padding-top: 24px;
}

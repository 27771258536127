@import '../../variables.module.scss';

.noticeBody {
  display: flex;
  justify-content: center;
  margin: 12px;
}

.tableContainer {
  border-radius: 4px;
  border: 1px solid $neutral700;
  background-color: white;
}

.tableContentWrapper {
  @include mobile {
    overflow-x: auto;
    width: auto;
    /* ToDo: set width back to 100% */

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.tableContentWrapper::-webkit-scrollbar {
  display: none;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.customNotice {
  color: $gray600;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  align-self: center;
}

.customNoticeContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 159px;
  padding-bottom: 159px;
}

@import '../../../variables.module.scss';

.container {
  border-top: 1px solid $borderSoft200;
  border-bottom: 1px solid $borderSoft200;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 0 32px;
  margin-bottom: 28px;

  > div {
    cursor: pointer;
    padding: 14px 0;
    color: $textSub600;
  }

  > div:hover {
    color: $textStrong900;
  }

  > .selectedSection {
    color: $textStrong900;
    border-bottom: 2px solid $primaryBase;
  }

  .section {
    display: flex;
    gap: 6px;
    align-items: center;
  }
}

$neutral50: #fafbfc;
$neutral100: #f8f9fb;
$neutral400: #f5f5f5;
$neutral500: #efeff1;
$neutral700: #e5e5e7;
$neutral950: #a5acba;
$gray50v1: #858c95;
$gray100v1: #596574;
$gray300: #4c5560;
$gray400: #454d56;
$gray600v1: #383d43;
$gray700: #323539;
$gray950: #181818;
$primary25: #ecf5ff;
$primary50: #d3e7ff;
$primary600: #0a77ff;
$primary800: #125dcb;
$primary900: #1750b1;
$warning600: #eea23e;
$success600: #2ebf43;
$success700: #2aa63c;
$error50: #ffefee;
$error600: #e33b32;

/* aurora v2 */
$white: #fff;

$blue50: #eff4ff;
$blue500: #2970ff;
$blue900: #00359e;

$gray50: #f5f7fa;
$gray100: #f2f5f8;
$gray200: #e1e4ea;
$gray300: #cacfd8;
$gray400: #99a0ae;
$gray500: #717784;
$gray600: #525866;
$gray800: #222530;
$gray900: #181b25;
$gray950: #0e121b;

$green50: #e0faec;
$green500: #1fc16b;
$green900: #16643b;

$orange50: #fff1eb;
$orange500: #ff8447;

$purple50: #efebff;
$purple500: #7d52f4;

$red50: #ffebec;
$red500: #fb3748;
$red900: #8b1822;

$primaryLighter: $blue50;
$primaryBase: $blue500;
$primaryDark: $blue900;

$fadedLighter: $gray100;
$fadedBase: $gray500;

$warningLighter: $orange50;
$warningBase: $orange500;

$infoLighter: $blue50;
$infoBase: $blue500;

$errorLighter: $red50;
$errorBase: $red500;
$errorDark: $red900;

$successLighter: $green50;
$successBase: $green500;
$successDark: $green900;

$borderSoft200: $gray200;
$borderStrong900: $gray900;

$textSoft400: $gray400;
$textSub600: $gray600;
$textStrong950: $gray950;
$textStrong900: $gray900;
$textDisabled300: $gray300;

$bgWeak50: $gray50;
$bgSurface800: $gray800;
$bgStrong900: $gray900;

$overlayBase: rgba(2, 13, 23, 0.4);

$spacingMd: 8px;
$spacingXl: 16px;

$breakpoint-mobile: 599px;
@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}

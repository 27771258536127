@import '../../../../../variables.module.scss';

.panel {
  text-align: left;
  width: 280px;

  @include mobile {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: 0;
  }
}

.section {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@import '../../../../variables.module.scss';

.heading {
  color: $primaryBase;
  margin-top: 20px;
  margin-bottom: 8px;
}

.addressAccordion {
  margin-bottom: 0 !important;
}

.addButton {
  display: flex;
  justify-content: flex-start;
}

.loading {
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  cursor: pointer;
}

@import '../../../variables.module.scss';

.support {
  margin-top: 64px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support-heading-container {
  background-color: $neutral400;
  width: 100%;
}
.support-heading {
  padding-top: 56px;
  font-size: 36px;
  color: $gray950;
  width: 100%;
  background-color: $neutral400;
  text-align: center;
}

.support-subheading {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 68px;
}

.support-form-container {
  margin-top: 40px;
}

.support-form-instructions {
  color: $gray950;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 34px;
  text-align: center;
}

.support-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support-form-textarea {
  width: 556px;
  height: 180px;
  resize: none;
  border: 1px solid $neutral700;
  border-radius: 8px;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: 600;
  color: $gray50v1;
}

.success {
  color: $success600;
}

.failure {
  color: $error600;
}

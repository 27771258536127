@import '../../../../variables.module.scss';

.formContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formContent {
  padding-bottom: 84px;
}

.section {
  padding-bottom: 24px;
  padding-top: 24px;

  &:first-child {
    padding-top: 0;
  }
}

.sectionHeader {
  color: $textStrong900;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.sectionTitle {
  display: flex;
  gap: 8px;
  color: $textStrong900;
}

.formRow {
  display: flex;
  gap: 16px;
  margin: 8px 0;

  > * {
    flex: 1;
  }
}

.footer {
  color: $textStrong900;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.footerButtons {
  display: flex;
  gap: 16px;
  width: 100%;

  > * {
    flex: 1;
  }
}

@import '../../../variables.module.scss';

.filter {
  position: relative;
  user-select: none;

  @include mobile {
    width: 100%;
  }
}

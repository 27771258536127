@import '../../../../../variables.module.scss';

.popupContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  border-radius: 4px;
  border: none;
  align-items: flex-start;
  color: $textStrong900;
  font-size: 12px;
  padding: 12px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  color: $textSoft400;
  font-weight: 500;
}

.text {
  color: $textStrong900;
  font-weight: 400;
}

@import '../../../../variables.module.scss';

.summary {
  margin: 24px 0;
  padding: 16px;
  background-color: $bgWeak50;
  border-radius: 4px;
}

.row {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

@import '../../../../../variables.module.scss';

.container {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.end {
  background-color: $successBase !important;

  &:hover {
    background-color: $successDark !important;
  }
}

.locationLegend {
  display: flex;
  align-items: center;
  gap: 4px;
}

.prevIcon {
  color: $primaryBase;
}

.hereIcon {
  color: $bgWeak50;
  border: 1px solid $textStrong900;
  border-radius: 50%;
}

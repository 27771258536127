@import '../../../variables.module.scss';

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  gap: 4px;
}

.filled {
  color: $white;
}

.gray {
  &.filled {
    background-color: $fadedBase;
  }
  &.lighter {
    background-color: $fadedLighter;
    color: $fadedBase;
  }
}

.blue {
  &.filled {
    background-color: $primaryBase;
  }
  &.lighter {
    background-color: $primaryLighter;
    color: $primaryBase;
  }
}

.orange {
  &.filled {
    background-color: $warningBase;
  }
  &.lighter {
    background-color: $warningLighter;
    color: $warningBase;
  }
}

.red {
  &.filled {
    background-color: $errorBase;
  }
  &.lighter {
    background-color: $errorLighter;
    color: $errorBase;
  }
}

.purple {
  &.filled {
    background-color: $infoBase;
  }
  &.lighter {
    background-color: $infoLighter;
    color: $infoBase;
  }
}

.green {
  &.filled {
    background-color: $successBase;
  }
  &.lighter {
    background-color: $successLighter;
    color: $successBase;
  }
}

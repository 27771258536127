.pageContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% + 64px);
  height: 100vh;
  margin: -32px;
}

.contents {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-height: 0;
}

.mapContainer {
  flex: 1;
  height: 100%;
  position: relative;
}

.customMap {
  height: calc(100vh - 86px);
}

@import '../../../variables.module.scss';

.filter {
  position: relative;

  @include desktop {
    display: none;
  }

  @include mobile {
    width: 100%;
  }
}

@import '../../variables.module.scss';

.actionMenu {
  position: relative;
  cursor: pointer;
}

.active {
  color: $gray900;
  background-color: $gray50;
}

.popover {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 1000;
  width: 159px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid $gray200;
  background: $white;
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}

.optionContainer {
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $gray50;
  }
}

.option {
  text-align: left;
  color: $gray900;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@import '../../../variables.module.scss';

.navItem {
  height: 46px;
  padding: 0 24px;
  transition: padding 1s ease-in-out;
  cursor: pointer;
  font-size: 15px;
  color: $gray50v1;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  text-decoration: none;
}

.navItem:hover {
  color: $primary600;
}

.activeItem {
  color: $primary600;
  background-color: $primary25;
}

.navIcon {
  min-width: 22px;
}

.collapsed {
  padding: 0 17px;
}

.navItemText {
  white-space: nowrap;
}

@import '../../../variables.module.scss';

.roleSelect {
  @include desktop {
    min-width: 250px;
  }
  @include mobile {
    width: 100%;
  }
}

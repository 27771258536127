@import '../../variables.module.scss';

.pageHeader {
  margin-bottom: 34px;
}

.breadcrumbsContainer {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;

  @include mobile {
    display: none;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.headingText {
  font-size: 28px;
  font-weight: 500;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import './variables.module.scss';

.app {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: $gray50v1;
  height: 100vh;
  box-sizing: border-box;
}

.artyc-button {
  color: white;
  background-color: $primary600;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.appContainer {
  z-index: 1;
  height: 100%;
}

#modalContainer {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.toastContainer {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

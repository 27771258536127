@import '../../variables.module.scss';

.dashboardPage {
  max-width: 1096px;
  margin: 0 auto;
  padding-top: 32px;
}

.dashboardCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 12px;
  gap: 20px;

  @include desktop {
    flex-direction: row;
  }

  @include mobile {
    flex-direction: column;
  }
}

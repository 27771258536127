@import '../../../variables.module.scss';

.button {
  text-decoration: none;
  background: none;

  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  color: $textDisabled300;
}

.underline {
  text-decoration: underline;
}

.gray {
  color: $textSub600;

  &:hover {
    color: $textStrong900;
  }
}

.black {
  color: $textStrong900;
}

.primary {
  color: $primaryBase;

  &:hover {
    color: $primaryDark;
  }
}

.error {
  color: $errorBase;

  &:hover {
    color: $errorDark;
  }
}

.white {
  color: white;
}

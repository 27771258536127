@import '../../../../variables.module.scss';

.button {
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  padding: 8px 12px;
  border-radius: 6px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.round {
  padding: 6px;
  border-radius: 50%;
}

.loading {
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
  background-color: $bgWeak50;
  border-color: $bgWeak50;
}

@import '../../../variables.module.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $overlayBase;
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.statusModal {
  display: flex;
  width: 445px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid $borderSoft200;
  background: $white;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
}

.content {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.iconContainer {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-10, 10px);

  &.error {
    background-color: $errorLighter;
    color: $errorBase;
  }

  &.warning {
    background-color: $warningLighter;
    color: $warningBase;
  }

  &.information {
    background-color: $infoLighter;
    color: $infoBase;
  }

  &.success {
    background-color: $successLighter;
    color: $successBase;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.title {
  color: $textStrong900;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.176px;
}

.description {
  color: $textSub600;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.footer {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-top: 1px solid $borderSoft200;
  background: $white;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
  align-items: center;
  text-align: center;

  .textSection {
    align-items: center;
  }
}

@import '../../../variables.module.scss';

.banner {
  background-color: $errorBase;
  padding: 12px;
  border-radius: 4px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: white;
  }

  .dismissButton {
    color: white;

    &:hover {
      background: none;
    }

    &:active {
      background: none;
    }
  }
}

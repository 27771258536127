@import '../../../../variables.module.scss';

.section {
  padding: 16px 0;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $borderSoft200;
  }
}

.sectionTitle {
  color: $primary600;
  padding-bottom: 4px;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@import '../../../variables.module.scss';

.heading {
  margin-top: 48px;
  color: $gray600v1;
  margin-bottom: 12px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background: $white;
  color: $gray600v1;
  padding: 16px 20px;
  border-radius: 12px;
  min-width: 300px;
  border: 1px solid $neutral700;
  font-weight: 600;
}

.link {
  justify-content: flex-start;
  padding: 0;
}

.cardBottom {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

@import '../../../../../variables.module.scss';

.container {
  display: flex;
  height: 378px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  text-align: center;
}

.icon {
  color: $textStrong900;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  margin-bottom: 12px;
  background-color: $bgWeak50;
  border-radius: 999px;
}

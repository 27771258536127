@import '../../../../variables.module.scss';

.container {
  padding: 0 24px 24px 24px;
  font-size: 15px;
  display: flex;

  @include mobile {
    display: block;
    padding: 8px;
  }
}

.graphPanel {
  flex-grow: 1;
  margin-top: 24px;
}

.graphButtons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;

  @include mobile {
    margin-top: 12px;
  }
}

.downloadButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid;
  border-color: $neutral700;
}

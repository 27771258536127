@import '../../../variables.module.scss';

// Button type configuration map
$button-types: (
  primary: (
    base: $primaryBase,
    dark: $primaryDark,
    light: $primaryLighter,
    shadow: rgba(41, 112, 255, 0.08),
  ),
  error: (
    base: $errorBase,
    dark: $errorDark,
    light: $errorLighter,
    shadow: rgba(251, 55, 72, 0.08),
  ),
  warning: (
    base: $warningBase,
    dark: darken($warningBase, 10%),
    light: $warningLighter,
    shadow: rgba(255, 132, 71, 0.08),
  ),
  success: (
    base: $successBase,
    dark: $successDark,
    light: $successLighter,
    shadow: rgba(31, 193, 107, 0.08),
  ),
  neutral: (
    base: $bgStrong900,
    dark: $bgSurface800,
    light: $bgWeak50,
    border: $borderSoft200,
    border-active: $borderStrong900,
    text: $textSub600,
    shadow: rgba(113, 119, 132, 0.08),
  ),
);

// Common button styles
.button {
  border-radius: 4px;
  border: 1px solid transparent;
  text-decoration: none;
}

// Size variants
.md {
  padding: 10px;
}

.sm {
  padding: 8px;
}

.xs {
  padding: 6px;
}

.xxs {
  padding: 4px 6px;
}

// Style variants
.stroke {
  background-color: $white;
}

.ghost {
  background-color: transparent;
}

// Disabled state
.disabled {
  color: $textDisabled300;
  background-color: $bgWeak50;
  cursor: not-allowed;
}

// Mixins for button styles
@mixin button-variant($type-config) {
  &:active {
    box-shadow: 0px 0px 0px 3px map-get($type-config, shadow);
  }

  &.filled {
    background-color: map-get($type-config, base);
    color: $white;

    &:hover {
      background-color: map-get($type-config, dark);
    }
  }

  &.stroke {
    $border-color: if(
      map-has-key($type-config, border),
      map-get($type-config, border),
      map-get($type-config, base)
    );
    $text-color: if(
      map-has-key($type-config, text),
      map-get($type-config, text),
      map-get($type-config, base)
    );

    border: 1px solid $border-color;
    color: $text-color;

    &:hover {
      background-color: map-get($type-config, light);
    }

    &:active {
      border: 1px solid
        if(
          map-has-key($type-config, border-active),
          map-get($type-config, border-active),
          map-get($type-config, base)
        );
      background-color: $white;
    }
  }

  &.ghost {
    $text-color: if(
      map-has-key($type-config, text),
      map-get($type-config, text),
      map-get($type-config, base)
    );
    color: $text-color;

    &:hover {
      background-color: map-get($type-config, light);
    }

    &:active {
      border: 1px solid
        if(
          map-has-key($type-config, border-active),
          map-get($type-config, border-active),
          map-get($type-config, base)
        );
      background-color: $white;
    }
  }
}

// Generate button type classes
@each $type, $config in $button-types {
  .#{$type} {
    @include button-variant($config);
  }
}

@import '../../../variables.module.scss';

.tableSearch {
  min-width: 300px;
  z-index: 1;
  @include mobile {
    width: 100%;
    min-width: 0px;
  }
}

.test {
  display: flex;
  gap: 10px;
}

.logs {
  margin-top: 10px;

  pre {
    white-space: pre-wrap;
  }
}

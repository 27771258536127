@import '../../../../variables.module.scss';

.popup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 4px;
  border: none;
  align-items: center;
  color: $textStrong900;
  font-size: 12px;
  padding: 12px;
  gap: 4px;
}

.title {
  font-weight: 500;
}

.serialNumber {
  font-size: 12px;
  font-weight: 400;
}

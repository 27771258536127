@import '../../../variables.module.scss';

.artyc-login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 32px;
  padding-right: 28px;
  padding-left: 28px;
  padding-top: 27px;

  hr {
    border: none;
    background-color: $neutral700;
    height: 1px;
    margin-bottom: 14px;
    &.error {
      background-color: $error600;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: $gray50v1;

    &.checkbox {
      color: $gray950;
      font-size: 12px;
    }
  }

  input[type='text'],
  input[type='password'] {
    font-size: 12px;
    font-weight: 500;
    padding-top: 11px;
    padding-bottom: 15px;
    border: none;
    &:focus {
      outline: none;
    }
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    accent-color: $primary600;
  }

  a {
    text-decoration: none;
    color: $primary600;
    font-size: 12px;
    font-weight: 500;
  }

  .form-footer {
    margin-top: 10px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .errmsg {
    color: $error600;
    margin-bottom: 8px;
  }

  button {
    border-radius: 10px;
    border: none;
    color: $white;
    background-color: $primary600;
    width: auto;
    font-size: 12px;
    font-weight: 700;
    padding: 16px 160px;
    &:hover {
      box-shadow: 0 0 10px;
    }
    &:not([disabled]) {
      cursor: pointer;
    }
    &[disabled] {
      color: $gray50v1;
      background-color: $neutral400;
      box-shadow: none;
      cursor: auto;
    }
  }

  .reset-submit {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 36px;
  }

  .back-to-login {
    display: block;
    text-align: center;
    margin-top: 28px;
  }
}

.card-contents {
  margin: 42px 28px 32px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

svg.confirmation-icon {
  fill: $primary600;
  height: 56px;
  width: 56px;
}

p.main-content {
  color: $gray950;
  font-size: 28px;
  font-weight: bold;
  margin-top: 16px;
}

p.sub-content {
  color: $gray50v1;
  font-size: 14px;
  margin-top: 6px;
}

button.registration-button {
  border-radius: 10px;
  border: none;
  color: $white;
  background-color: $primary600;
  width: 100%;
  font-size: 12px;
  font-weight: 600px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 56px;
  &:hover {
    cursor: pointer;
  }
}

@import '../../../variables.module.scss';

.dashboardCard {
  padding: 16px 20px;
  border: 1px solid $neutral700;
  border-radius: 12px;
  color: $gray600v1;
  background-color: $white;

  @include desktop {
    width: 352px;
  }

  @include mobile {
    width: 343px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 52px;
  gap: 10px;
}

.heading {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.metric {
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.015em;
}

@import '../../../../../variables.module.scss';

.container {
  position: relative;
  padding: 5px 24px 0px 20px;
  margin-left: 6px;
  cursor: pointer;
  height: 50px;
  z-index: 1;

  &:active {
    cursor: grabbing;
  }
}

.svg {
  display: block;
}

.path {
  stroke: $primaryBase;
  stroke-width: 2;
  fill: none;
}

.resizeBar {
  fill: $primaryBase;
  cursor: grab;

  &:hover {
    fill: $primaryDark;
  }

  &:active {
    cursor: grabbing;
  }
}

.selectionRect {
  fill: rgba($primaryBase, 0.1);
  stroke: $primaryBase;
  stroke-width: 2;
  vector-effect: non-scaling-stroke;
}

.resizeBarHitArea {
  fill: transparent;
}

@import '../../../variables.module.scss';

.infoCard {
  border-radius: 6px;
  border: 1px solid;
  border-color: $neutral500;
  padding: 12px;
  background-color: $neutral100;
}

.infoCardHeader {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid;
  border-color: $neutral500;
}

.infoCardBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;

  @include mobile {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
}

.headingText {
  color: $gray600v1;
  font-weight: 600;
}

.subheadingText {
  color: $gray50v1;
  font-weight: 500;
}

.bodyText {
  color: $gray600v1;
  font-weight: 500;
}

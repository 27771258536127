@import '../../../../variables.module.scss';

.popup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 4px;
  border: none;
  align-items: flex-start;
  color: $textStrong900;
  font-size: 12px;
  padding: 12px;
}

.deviceInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.serialNumber {
  font-size: 14px;
}

tagContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tagRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tag {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: $bgWeak50;
}

.tagText {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@import '../../../variables.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  border-bottom: 1px solid $borderSoft200;
  height: 86px;
  min-height: 86px;
  width: 100%;
}

.leftText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.leftTextTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.27px;
  color: $textStrong900;
}

.leftTextDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: $textSoft400;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.companySelect {
  min-width: 200px;
}

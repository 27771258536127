@import '../../../../variables.module.scss';

.pageContainer {
  margin: -32px;
}

.leftHeader {
  display: flex;
  gap: 12px;
  align-items: center;
}

.header {
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .buttons {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}

.heading {
  color: $textStrong900;
  display: flex;
  align-items: center;

  a {
    color: $textStrong900;
    text-decoration: none;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.divider {
  margin-top: 12px;
}

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 32px;
  padding: 32px;

  @include mobile {
    grid-template-columns: 1fr;
  }

  .leftColumn {
    grid-column: span 4;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mobile {
      grid-column: 1;
    }
  }

  .rightColumn {
    grid-column: span 8;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include mobile {
      grid-column: 1;
    }
  }
}

.legSelector {
  border-top: 1px solid $borderSoft200;
  border-bottom: 1px solid $borderSoft200;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 0 32px;

  > div {
    cursor: pointer;
    padding: 14px 0;
    color: $textSub600;
  }

  > div:hover {
    color: $textStrong900;
  }

  > .selectedLeg {
    color: $textStrong900;
    border-bottom: 2px solid $primaryBase;
  }

  .leg {
    display: flex;
    gap: 6px;
    align-items: center;
  }
}

.cardRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  @include mobile {
    grid-template-columns: 1fr;
  }
}

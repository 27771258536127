@import '../../../../../variables.module.scss';

.container {
  padding: 0 20px 20px 20px;
}

.instructions {
  margin-bottom: 16px;
  color: $gray900;

  > div {
    margin-bottom: 12px;
  }
}

.fieldsContainer {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-evenly;
  color: $gray900;
}

.buttons {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.error {
  color: $error600;
  margin-top: 12px;
  font-weight: bold;
}

@import '../../../../../variables.module.scss';

.noGps {
  padding: 10px;
  color: white;
  background-color: $fadedBase;
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 100;
}

.mapContainer {
  position: relative;
}

@import '../../../../../variables.module.scss';

.graphPanel {
  flex-grow: 1;
  margin-top: 24px;
}

.graphDropdown {
  display: flex;
  justify-content: flex-end;
}

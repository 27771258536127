@import '../../variables.module.scss';

.banner {
  padding: 17px 12px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  border-radius: 8px;

  @include desktop {
    align-items: center;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.mainContent {
  display: flex;
  align-items: center;
  color: white;
  gap: 20px;

  @include desktop {
    gap: 20px;
    align-items: center;
  }

  @include mobile {
    gap: 12px;
    align-items: flex-start;
  }
}

.bannerIcon {
  flex-shrink: 0;
  color: white;

  @include desktop {
    height: 24px;
    width: 24px;
  }
  @include mobile {
    height: 20px;
    width: 20px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 16px;
  font-weight: 800;
  padding-left: 0px;

  @include mobile {
    padding-left: 32px;
    width: 100%;
  }
}

.title {
  font-weight: 600;
}

.subtitle {
  font-weight: 400;
}

.textSize {
  @include desktop {
    font-size: 16px;
    line-height: 24px;
  }
  @include mobile {
    font-size: 14px;
    line-height: 20px;
  }
}

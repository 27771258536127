@import '../../../variables.module.scss';

.drawer {
  border-left: 1px solid $neutral500;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100% !important;
  }
  @include desktop {
    width: 432px !important;
  }
}

.title {
  color: $gray600v1;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid $neutral500;

  @include mobile {
    padding: 16px;
  }
}

.body {
  overflow-x: hidden;
  height: 100%;
  background-color: $white;
}

.bodyForElement {
  padding: 24px;

  @include mobile {
    padding: 16px;
  }
}

.footer {
  color: $gray600v1;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: $white;
  border-top: 1px solid $neutral500;

  @include mobile {
    padding: 16px;
    // pretty ugly for now but it's a workaround to fix the footer not being visible on certain browsers
    padding-bottom: 50px;
  }
}

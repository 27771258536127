@import '../../../variables.module.scss';

.group {
  color: $textSub600;
  line-height: 20px;
  letter-spacing: -0.084px;
  font-size: 14px;

  align-self: stretch;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.tile {
  box-sizing: border-box;
  background: $white;
  padding: 8px;
  min-width: fit-content;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid $borderSoft200;
  cursor: pointer;

  display: flex;
  align-items: center;
  flex-grow: 1;

  &:hover,
  &.selected {
    border-color: $primaryBase;
  }

  &.selected {
    .label,
    .count {
      color: $primaryBase;
    }
  }
}

.tileContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 60px;
}

.label {
  min-height: 22px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.count {
  font-weight: 500;
}

@import '../../../variables.module.scss';

.hideForDesktop {
  width: 100%;
  @include desktop {
    display: none;
  }
}

.hideForMobile {
  @include mobile {
    display: none;
  }
}

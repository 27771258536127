@import '../../../variables.module.scss';

.checklistContent {
  > section {
    border-top: 1px solid $neutral700;
    padding-top: 20px;
  }

  > section:first-of-type {
    border-top: none;
    padding-top: 0;
  }
}

.titleText {
  color: $gray600v1;
  font-weight: 600;
  margin-bottom: 16px;
}

.search {
  padding-bottom: 16px;
}

.checkbox {
  color: $gray50v1;
  font-weight: 500;
  margin-bottom: 16px;
}

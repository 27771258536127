@import '../../../variables.module.scss';

.inputRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 16px;

  > div {
    flex-grow: 1;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 16px;
}

.buttonContainer {
  display: flex;
  gap: 12px;

  > div {
    flex-grow: 1;
  }
}

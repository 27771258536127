@import '../../../variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  gap: 16px;
  padding: 20px;
}

.iconWrapper {
  padding: 8px;
  border-radius: 8px;
  background-color: #f2f5f8;
  height: 40px;
  width: 40px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #181b25;
}

.list {
  padding-left: 20px;
  padding-top: 14px;
}

.buttonFooter {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e1e4ea;
  gap: 12px;
}

@import '../variables.module.scss';

.filterGroup {
  display: flex;
  gap: 16px;

  @include mobile {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
  }
}

.statusBadgeWrapper {
  display: inline-block;
}

.statusBadgeContent {
  border: 1px solid $neutral700;
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.statusBadgeSuccess {
  color: green;
}

.statusBadgeFaded {
  color: red;
}

@import '../../../variables.module.scss';

.navBar {
  display: flex;
  background-color: white;
  flex-shrink: 0;
  z-index: 2;
  position: relative;

  @include desktop {
    padding-top: 28px;
    padding-bottom: 24px;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 32px;
    height: 100vh;
    border-right: 1px solid $neutral700;
    transition: width 1s ease-in-out;

    &.expanded {
      width: 280px;
    }

    &.collapsed {
      width: 56px;
    }
  }

  @include mobile {
    justify-content: space-between;
    align-items: center;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
  position: relative;
}

.slideoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 13px;
}

.slideoutLogo {
  margin: 2px 0;
  margin-left: 16px;
  padding: 16px 0;
  padding-left: 16px;
  display: flex;
  align-items: center;
  color: $primary600;
}

.slideoutCloseButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: $gray50v1;
}

.mobileNavBar {
  @include desktop {
    display: none;
  }
}

.desktopNavBar {
  @include mobile {
    display: none;
  }
}

.navBarBodyContainer {
  display: flex;
  flex-direction: row;
}

.handleWrapper {
  position: absolute;
  right: -24px;
  top: 0;
  bottom: 0;
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltipWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.handleContainer {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $gray400;
  z-index: 3;

  svg {
    width: 20px;
    height: 20px;
  }
}

.iconCollapsed {
  transform: rotate(180deg);
}

.navBarHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.navItem {
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import '../../../variables.module.scss';

.buttonGroup {
  display: flex;
  overflow: hidden;

  @include mobile {
    width: 100%;
  }
}

.firstButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.lastButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}

.middleButton {
  border-radius: 0;
  border-left-width: 0;
}

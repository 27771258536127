@import '../../../../../variables.module.scss';

// TODO: move this out of journey since it is a general map stylesheet
.mapboxgl-popup-content {
  background: $borderSoft200 !important;
  padding: 1px !important;
  border-radius: 4px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12px;

  .label {
    color: #fff;
    font-weight: 500;
  }

  .text {
    color: $textSoft400;
    font-weight: 400;
  }
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: $borderSoft200;
  margin-top: 15px;
}

.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
  border-top-color: $borderSoft200;
  margin-bottom: 15px;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: $borderSoft200;
  margin-left: 15px;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: $borderSoft200;
  margin-right: 15px;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  display: none;
}

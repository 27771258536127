@import '../../../variables.module.scss';

.textInputWrapper {
  position: relative;
}

.iconLeft {
  position: absolute;
  left: 14px;
  top: 10px;
}

.iconRight {
  position: absolute;
  right: 14px;
  top: 10px;
}

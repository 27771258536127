@import '../../../variables.module.scss';

.wrapper {
  display: inline-block;
}

.content {
  color: $textSub600;
  border: 1px solid $neutral700;
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-wrap: nowrap;
}

.green {
  color: $success600;
}

.gray {
  color: $fadedBase;
}

.blue {
  color: $primaryBase;
}

.orange {
  color: $warningBase;
}

.purple {
  color: $infoBase;
}

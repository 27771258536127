@import '../../../variables.module.scss';

.navBarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include mobile {
    padding: 16px;
    padding-right: 8px;
  }
}

.logoLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 28px;
  transition: none;

  @include desktop {
    &.collapsed {
      margin-left: 10.5px;
    }
  }

  @include mobile {
    margin-left: 0px;
    transition: none;
  }
}

.withTransition {
  transition: margin-left 1s ease-in-out;
}

.slideoutToggle {
  padding: 8px;
  cursor: pointer;

  @include desktop {
    display: none;
  }
}

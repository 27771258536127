@import '../../../../../variables.module.scss';

.row {
  margin-bottom: 4px;
}

.modalContent {
  padding: 20px;
}

.banner {
  margin: -12px -12px 12px -12px;
  margin-top: -12px;
  padding: $spacingMd 12px;
  background-color: $bgWeak50;
  display: flex;
  gap: 3px;

  .bannerIcon {
    color: $successBase;
  }
}

.currentStats {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  flex-wrap: wrap;

  .statCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-right: 1px solid $borderSoft200;
    width: 33%;
  }

  .statCard:last-child {
    border-right: none;
  }

  .statIcon {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }

  .internal {
    background-color: $primaryLighter;
    color: $primaryBase;
  }

  .external {
    background-color: $warningLighter;
    color: $warningBase;
  }

  .battery {
    background-color: $fadedLighter;
    color: $fadedBase;
  }
}

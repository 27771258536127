@import '../../../../variables.module.scss';

.accordion {
  margin-bottom: 0 !important;
}

.box {
  border-radius: 4px;
  border: 1px solid $borderSoft200;
  padding: 12px;

  .body {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $borderSoft200;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

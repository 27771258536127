@import '../../../variables.module.scss';

.disabled {
  color: $textDisabled300 !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }
}

.button {
  color: $textSub600;
  border-radius: 4px;
  border: 1px solid transparent;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: $bgWeak50;
  }

  &:active {
    color: $white;
    background-color: $bgStrong900;
  }
}

.lg {
  width: 25px;
  height: 25px;
}

.md {
  width: 20px;
  height: 20px;
}

.rounded {
  border-radius: 999px;
}

.stroke {
  background-color: $white;
  border: 1px solid $borderSoft200;
}

.ghost {
  background-color: transparent;
}

.white {
  background-color: $white;
}

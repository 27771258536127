@import '../../../../variables.module.scss';

.detailsHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.filterRow {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.searchInput {
  flex-grow: 1;
}

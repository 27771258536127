@import '../../../variables.module.scss';

.checkbox {
  color: $gray50v1;
  font-weight: 500;
}

.companiesList {
  margin-top: 12px;
  display: grid;
  column-gap: 12px;
  row-gap: 12px;
  grid-template-columns: 1fr 1fr;
}

.companyFilter {
  @include mobile {
    display: none;
  }
}

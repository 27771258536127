@import '../../../../variables.module.scss';

.formContainer {
  padding: 12px 16px;
  margin: 0;
  border-top: 1px solid $borderSoft200;
  display: flex;
  flex-direction: column;
}

.spinner {
  padding: 20px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  max-height: 70vh;
}

.scrollableContent {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
}

.stickyFooter {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 16px 24px;
  border-top: 1px solid $borderSoft200;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  z-index: 1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

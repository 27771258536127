.form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 16px;
  gap: 16px;
}

.buttonContainer {
  margin-top: 24px;
  display: flex;
  gap: 12px;

  > div {
    flex-grow: 1;
  }
}

@import '../../../../variables.module.scss';

.header {
  display: flex;
  flex-direction: column;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.statusBadgeContainer {
  position: absolute;
  right: 45px;
}

.subheader {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: $textSub600;
}

.headerSerialNumber {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.content {
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 12px;
  padding-top: 0;
}

.tagContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tagGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tagRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tag {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: $bgWeak50;
}

.tagText {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.tagLastUpdated {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $textSoft400;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 12px;
  border-top: 1px solid $borderSoft200;
}

.footerLink {
  color: $blue500;
  text-decoration: none;

  &.disabled {
    color: $textDisabled300;
    cursor: default;
  }
}

.warning {
  color: $red500;
}

@import '../../../variables.module.scss';

.step {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  flex-grow: 1;

  &:last-child {
    flex-grow: 0;
  }
}

.stepProgress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.stepIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray300;
  width: 16px;
  height: 16px;

  &.complete {
    color: $blue500;
  }
}

.stepLine {
  flex-grow: 1;
  border-top: 2px dotted transparent;
  border-image: repeating-linear-gradient(
      to right,
      $gray300 0px,
      $gray300 4px,
      transparent 4px,
      transparent 10px
    )
    1;
  border-radius: 4px;

  &.complete {
    border-image: none;
    border-top-style: solid;
    border-top-color: $blue500;
  }
}

.stepLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: $gray300;

  &.complete {
    color: $gray600;
  }
}

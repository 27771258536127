@import '../../../../variables.module.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 20px;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }
}

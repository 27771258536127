@import '../../../variables.module.scss';

.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.checkboxContainer {
  position: relative;
  border-radius: 4px;
}

.checkbox {
  margin: 0;
  appearance: none;
  border-radius: 4px;
  border: 2px solid;
  display: grid;
  width: 16px;
  height: 16px;
}

.checkIcon {
  position: absolute;
  top: 2px;
  left: 2px;
}

@import '../../../variables.module.scss';
.companySelect {
  @include desktop {
    min-width: 250px;
  }

  @include mobile {
    width: 100%;
  }
}

@import '../../variables.module.scss';

.tableHeader {
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
  color: $gray600v1;
  gap: 12px;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.topRow {
  display: flex;
  justify-content: flex-end;

  @include mobile {
    gap: 8px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  > div {
    flex-grow: 1;
  }
}

.flexGrow {
  flex: 1;
  min-width: 0;
  width: 100%;
}

@import '../../../../../variables.module.scss';

.report {
  max-width: 1268px;
  padding: 20px;
  margin: auto;
  text-align: left;
}

.actionBar {
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .subBar {
    display: flex;
    gap: 20px;
  }

  @media print {
    display: none;
  }
}

.header {
  margin-top: 96px;
  color: $gray600v1;

  @media print {
    margin-top: 0;
  }
}

.summaryContainer {
  margin-top: 32px;
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
}

.toggleContainer {
  display: flex;
  gap: 20px;
  margin-top: 24px;

  @media print {
    display: none;
  }
}

.considerations {
  margin-top: 64px;
  color: $gray600v1;
  white-space: pre-wrap;

  .considerationHeader {
    font-weight: 600;
    margin-bottom: 16px;
  }

  @media print {
    margin-top: 0;
    page-break-before: always;
  }
}

.printOnly {
  display: none;

  @media print {
    display: block;
  }
}

.logo {
  margin-top: 12px;
  margin-bottom: 38px;
}

.questions {
  margin-top: 100px;
  color: $gray600v1;

  .questionsHeader {
    font-weight: 600;
    margin-bottom: 16px;
  }
}

@import '../../../../variables.module.scss';

.notice {
  padding: 20px;
  padding-bottom: 0;
}

.selects {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  align-self: stretch;
  border-top: 1px solid $borderSoft200;
  background: $white;
}

.reportContainer {
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1000;
  background: white;
}

@import '../../../../variables.module.scss';

.button {
  width: 100%;
}

.header {
  margin: -24px -24px 0 -24px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $neutral500;
}

.legHeading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sectionHeading {
  margin-top: 32px;
  margin-bottom: 12px;
}

.journeySummary {
  background: $neutral100;
  padding: 12px 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.legInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoPair {
  display: flex;
  gap: 16px;

  .label {
    width: 140px;
  }

  .value {
    font-weight: 500;
  }
}
